import { Input, Pagination, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { ExpandYourAreaApi } from 'src/api'
import { ModalExpandYourAreaAction } from 'src/components'
import { EExpandYourReachAreaActions, ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { EExpandYourAreaActionsText, IExpandYourAreaModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useAuthorization, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderCandidatesColumns } from './columns'

const PAGE_TITLE = 'Expand Your Area'

const actionOptions = Object.values(EExpandYourReachAreaActions).map((value) => ({
  label: EExpandYourAreaActionsText[value],
  value
}))

export const ExpandYourArea: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService(ExpandYourAreaApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<IExpandYourAreaModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{
    keyword?: string
    action?: string[]
  } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset'])
  })

  const onDelete = useCallback((record: IExpandYourAreaModel) => {
    GlobalAction.setLoading(true)
    from(ExpandYourAreaApi.destroy(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next: () => fetch(pagination),
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  const onUpdate = useCallback((record: IExpandYourAreaModel) => {
    setDataSource((prev) => ({
      ...prev,
      rows: prev.rows?.map(
        (item) => item.id === record.id
          ? { ...item, ...record }
          : item
      )
    }))
  }, [])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.EXPAND_YOUR_AREA,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2 gap-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search action..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />

          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={[]}
            onChange={(value) => {
              setFilter((prev) => ({
                ...prev,
                action: value
              }))
            }}
            options={actionOptions}
          />
        </div>

        <ModalExpandYourAreaAction afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}>
          Create
        </ModalExpandYourAreaAction>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        columns={renderCandidatesColumns({
          onClickDelete: is(ERoleType.SUPER_ADMIN)
            ? onDelete
            : undefined,
          onUpdate
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
