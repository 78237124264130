import { Button, Input, Pagination, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { BlogPostApi } from 'src/api'
import { ModalBlogPost } from 'src/components'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { EQueueJobStatus, IBlogPostModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

export const PostsManagement: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const _paginationService = useMemo(() => new PaginationService<IBlogPostModel>(BlogPostApi), [])
  const [loading, setLoading] = useState(false)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{ status?: EQueueJobStatus } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(omit(pagination, ['page', 'limit', 'offset']))
  useEffect(() => {
    setFilter(omit(pagination, ['page', 'limit', 'offset']))
  }, [pagination])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(setLoading)
  }, [_paginationService, unsubscribe$])

  const onDestroy = useCallback((record: IBlogPostModel) => {
    setLoading(true)
    from(BlogPostApi.destroy(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          fetch(pagination)
          NotifyUtils.success({ message: 'Delete post successfully' })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.BLOG_POST_MANAGEMENT,
      label: 'Post Management'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const handleOnSearch = useCallback(() => {
    const { status, ...paging } = pagination
    setPagination({ ...paging, page: 1 })
    return pushPagination({
      ...paging,
      page: 1,
      ...filter
    })
  }, [filter, pagination, pushPagination, setPagination])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center fx-wrap-wrap gap-2">
          <Input
            allowClear
            placeholder="Search post..."
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.keyword as string || ''}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />

          <Select
            allowClear
            disabled={loading}
            placeholder="Featured status"
            style={{ flex: '0 0 120px' }}
            value={filter.isFeatured}
            options={[
              { label: 'Featured status', value: '', disabled: true },
              { label: 'Featured', value: 'true' },
              { label: 'Not Featured', value: 'false' }
            ]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              isFeatured: value || ''
            }))}
          />

          <Select
            allowClear
            disabled={loading}
            placeholder="Publish status"
            style={{ flex: '0 0 120px' }}
            value={filter.isPublish}
            options={[
              { label: 'Publish status', value: '', disabled: true },
              { label: 'Publish', value: 'true' },
              { label: 'Not Publish', value: 'false' }
            ]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              isPublish: value || ''
            }))}
          />
        </div>

        <div className="fx gap-1">
          <Button
            onClick={() => pushPagination({
              ...pagination,
              keyword: '',
              isFeatured: '',
              isPublish: ''
            })}
          >
            Clear All
          </Button>
          <Button type="primary" onClick={handleOnSearch}>Search</Button>
          <ModalBlogPost afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}>
            Create
          </ModalBlogPost>
        </div>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          onUpdated: () => fetch(pagination),
          onDestroy: is(ERoleType.SUPER_ADMIN)
            ? onDestroy
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
